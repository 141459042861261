import api from "@/service/mapManger";

let lat = 25.046273;
let long = 121.517498;

const state = {
  mapData: {
    zoom: 15,
    mapcenter: [lat, long]
  }
};

const getters = {};

const actions = {
  async changeZoom({
    state,
    commit
  }, data) {
    const awita = await commit("setZoom", data);
    return awita;
  },

  async changeMapCenter({
    state,
    commit
  }, data) {
    const awit = await commit("setMapCenter", data);
    return awit
  },

  distanceCount({
    state,
    commit
  }, data) {
    const result = api.googleDistanceCount(data);
    result.then((data) => {
      if (data.data.state) {
        commit("schedule/setOneScheduleDay", data.data.result, {
          root: true
        });
      }
    });
    return result;
  }
};

const mutations = {
  setZoom(state, zoom) {
    state.mapData.zoom = zoom;
  },

  setMapCenter(state, itude) {
    state.mapData.mapcenter = itude;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
