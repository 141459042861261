import store from "@/store"

export default {
  name: 'ForgetPassWord',
  methods: {
    sendMail() {
      store.dispatch("user/sendCheckKey", this.email).then((data) => {
        if (data.data.state) {
          const t = this.StringReplace(this.langPub.Login.checkKeycontext, ["{email}"], [
            this.email
          ]);
          this.unlock = true
          this.page = 1;
          store.dispatch("Public/addAlert", {
            title: "已經送出信件",
            type: "success",
            msg: t
          });
        } else {
          this.emailerror = true;
          store.dispatch("Public/addAlert", {
            title: "Email輸入錯誤",
            type: "danger",
            msg: "Email輸入錯誤"
          });
        }
      }).catch((error, data) => {
        console.log(error);
      });
    },
    changepwfunc() {
      store.dispatch("user/CheckKey", {
        email: this.email,
        KeyCode: this.YouKey
      }).then((data) => {
        if (data.data.state) {
          this.page = 2;
        }

      });
    },
    finish() {
      store.dispatch("user/ChangePw", {
        email: this.email,
        KeyCode: this.YouKey,
        changepw: this.changepw
      }).then((data) => {
        if (data.data.state) {
          this.page = 3;

        }
      });
    },
    StringReplace(string, replace, restring) {
      replace.forEach((item, keys) => {
        string = string.replace(item, restring[keys]);
      })
      return string;
    }
  },
  data() {
    return {
      page: 0,

      email: "",
      YouKey: "",
      unlock: false,
      doublechangepw: "",
      emailerror: false,
      changepw: ""
    };
  },
  props: {
    show: Boolean,
  },
  computed: {
    langPub: {
      get() {
        return store.state.Public.lang;
      }
    },
    mdshow: {
      get() {
        return this.show;
      },
      set(data) {
        this.$emit("update:show", data);
      }
    }
  }
};
