import Vue from 'vue';
import 'iCheck';
import "niceSelect";
import 'moment';
// import "@/plugins/js/daterangepicker.js";
import "magnific";

Vue.directive('icheckStyle', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function (el, binding) {
    // 聚焦元素
    // <input type="checkbox" class="oc" :class='"SD-"+list.value+"-"+data.value' :value="list.value+'-'+data.value"
    // v-icheckStyle='checkedlist'
    // :checked='checkedlist.indexOf(list.value+"-"+data.value)>-1'>
    const ich = $(el).iCheck({
      checkboxClass: 'icheckbox_square-grey',
      radioClass: 'iradio_square-grey'
    });
    ich.iCheck('update');
    ich.on("ifToggled", function (event) {
      let bvalue = binding.value;
      let value = event.target.value;
      let inputtype = event.target.type;
      if (inputtype == 'checkbox' && !binding.modifiers.single) {
        if (event.target.checked) {
          if (value == "all") {
            bvalue.length = 0;
          } else {
            let allindex = bvalue.indexOf("all");
            if (allindex > -1) {
              bvalue.splice(allindex, 1);
            }
          }
          let index = bvalue.indexOf(value);
          if (index == -1) {
            bvalue.push(value);
          }

        } else {
          let index = bvalue.indexOf(value);
          if (index > -1) {
            bvalue.splice(index, 1);
          }
        }
      } else if (inputtype == 'radio' && !binding.modifiers.single) {
        bvalue.splice(0,1);
        bvalue.push(value);
        // bvalue[0] = value;
      } else if (binding.modifiers.single) {
        const check = event.target.checked;
        bvalue[binding.arg] = event.target.checked ? value : "0"
      }
      Vue.nextTick(() => {
        $(el).iCheck('update');
      });
    })
  },
  update: (el, binding) => {

    if (binding.modifiers.iszore) {
      let bvalue = binding.value;
      if (bvalue.length == 0) {
        let value = el.value;
        bvalue.push(value);
        $(el).iCheck("update");
      }
    }
    Vue.nextTick(() => {
      $(el).iCheck('update');
    });
  }
})
Vue.directive('magnificPopup', {
  inserted: (el, binding) => {

    // return ;
    const opened = (binding.value) ? (binding.value.open || (() => {
      return "";
    })) : (() => {});
    const close = (binding.value) ? (binding.value.close || (() => {
      return "";
    })) : (() => {});
    var magnificPopup = $.magnificPopup.instance;
    $(el).magnificPopup({
      type: 'inline',
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: 'auto',
      closeBtnInside: true,
      preloader: false,
      midClick: false,
      closeOnBgClick:false,
      removalDelay: 300,
      closeMarkup: '<button title="%title%" type="button" class="mfp-close"></button>',
      mainClass: 'my-mfp-zoom-in',
      alignTop: binding.modifiers.alignTop,
      callbacks: {
        beforeOpen: opened,
        close: close
      }
    });
    // mfp-content
    //mfp-container mfp-inline-holder mfp-content
    //mfp-container mfp-inline-holder
    //mfp-wrap mfp-close-btn-in mfp-align-top mfp-auto-cursor my-mfp-zoom-in mfp-ready
    //mfp-wrap mfp-close-btn-in mfp-auto-cursor my-mfp-zoom-in mfp-ready
  }
});
Vue.directive('niceSelect', {
  inserted: (el, binding) => {
    let nice;
    nice = $(el).niceSelect();
    console.log(el);
    console.log(binding);
    let values = [];
    $.each(binding.modifiers, (keys, item) => {
      values = keys;
    });
    nice.on('change', function (data) {
      binding.value[values] = $(el).val();
    })
  },
  update: (el, binding) => {
    $(el).niceSelect("update");
  },
  componentUpdated: (el) => {
    $(el).niceSelect("update");
  }
});
Vue.directive("toTop", {
  inserted: (el, binding) => {
    $(window).on('scroll', function () {
      console
      if ($(this).scrollTop() != 0) {
        $(el).show();
      } else {
        $(el).hide();
      }
    });
    $(el).on('click', function () {
      $('body,html').scrollTop(0);
    });
  }
});
Vue.directive("openModal", {
  inserted(el, binding) {
    console.log(binding);
    const modifiers = binding.modifiers;
    const showoption = binding.value || false;
    const id = (modifiers.length>0)?modifiers[0]:null;
    // return ;
    if(id){
      const option = {
        backdrop:showoption.backdrop || true,
        keyboard:showoption.keyboard||true,
        focus:showoption.focus||true,
        show:showoption.show||false
      }
      $("#"+id).modal(option);
      $(el).click(()=>{
        $("#"+id).modal("show");
      })
    }
  }
})