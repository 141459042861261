import Vue from 'vue';
import store from '@/store';
import Router, {
  RedirectOption
} from 'vue-router';

Vue.use(Router);

const REDIRECT_TIME_LIMIT = 3600000;

let getpage = (name, path = 'views') => () => {
  return import(`./${path}/${name}.vue`);
}

if (navigator.geolocation) {

} else {
  console.log("Geolocation is not supported by this browser.");
}

const redirect = () => {
  const defaultRoute = {
    name: 'index'
  };

  const local = JSON.parse(window.localStorage.getItem('vuex'));

  if (!local) {
    return defaultRoute;
  }

  const {
    route,
    lastLaunch
  } = local;

  const now = new Date().valueOf();

  if ((now - lastLaunch) < REDIRECT_TIME_LIMIT) {
    return (!route.name || route.name == "thought") ? defaultRoute : {
      name: route.name
    };
  }

  return defaultRoute;
};

const router = new Router({
  mode: process.env.VUE_APP_MODEL,
  base: process.env.VUE_APP_URL,
  fallback: true,
  routes: [{
      path: '/',
      name: "thought",
      redirect: redirect(),
    },
    {
      path: '/index',
      component: getpage("index"),
      children: [{
        path: '/index',
        name: 'index',
        components: {
          header: getpage("Public/headerinfo"),
          context: getpage("Context/Index"),
          footer: getpage("Public/footerinfo")
        },
        meta: {
          title: '旅遊百匯',
          header: true
        },
        redirect: {
          name: "schedule"
        }
      }, {
        path: '/personalPoint',
        name: 'personalPoint',
        components: {
          header: getpage("Public/headerinfo"),
          context: getpage("Context/PersonalPoint"),
          footer: getpage("Public/footerinfo")
        },
        meta: {
          title: '旅遊百匯-景點編輯',
          header: true
        }
      }, {
        path: '/personalPhoto',
        name: 'personalPhoto',
        components: {
          header: getpage("Public/headerinfo"),
          context: getpage("Context/PersonalPhoto"),
          footer: getpage("Public/footerinfo")
        },
        meta: {
          title: '旅遊百匯-景點編輯',
          header: true
        }
      }, {
        path: '/schedule',
        name: 'schedule',
        components: {
          header: getpage("Public/headerinfo"),
          context: getpage("Context/schedule"),
          footer: getpage("Public/footerinfo")
        },
        meta: {
          title: '旅遊百匯-行程列表',
          header: true
        }
      }, {
        path: '/Edit/:Schedule',
        name: 'Edit',
        components: {
          header: getpage("Public/headerinfo"),
          context: getpage("Context/EditSchedule"),
          footer: getpage("Public/footerinfo")
        },
        meta: {
          title: '旅遊百匯-行程編輯',
          search: true,
        }
      }, {
        path: '/Quote/:Schedule',
        name: 'Quote',
        components: {
          header: getpage("Public/headerinfo"),
          context: getpage("Context/Quote"),
          footer: getpage("Public/footerinfo")
        },
        meta: {
          title: '旅遊百匯-行程報價',
          header: true
        }
      }, ]
    }, {
      path: '/QRcode/:level/:scheduleuid/:sourcemember',
      name: 'ShareQRcode',
      component: getpage("ShareView"),
      meta: {
        title: '達跡旅行社',
      }
    }, {
      path: '/login',
      name: 'login',
      component: getpage("Login"),
      meta: {
        title: '達跡旅行社'
      }
    }, {
      path: '/logout',
      name: 'logout',
      meta: {
        title: '達跡旅行社'
      }
    },
    {
      path: '/tranto/:Schedule',
      name: 'tranto',
      meta: {
        title: '轉換中'
      }
    }
  ],
});

router.beforeEach(async (to, from, next) => {
  document.title = (to.meta) ? to.meta.title : document.title;
  if (to.name == 'logout') {
    store.dispatch("user/logout").then(function (data) {
      store.dispatch("Public/addAlert", {
        type: "success",
        title: store.state.Public.lang.Login.logoutsuccess,
        msg: store.state.Public.lang.Login.logoutsuccess
      });

      next({
        name: "login"
      });
    });
  } else if (to.name == "login") {
    next();
  } else if (to.name == "tranto") {

    const response = await store.dispatch("user/tranToSchedule", {
      Schedule: to.params.Schedule,
      token: to.query.token
    })

    if (response.status) {
      next({
        name: "Edit",
        params: {
          Schedule: response.schedule
        }
      })
    } else {
       alert("無此使用者，將關閉畫面");
      window.opener = null
      window.open("about:blank", "_self").close()
      window.close();

      //
    }
  } else {
    const checkmemberfunc = store.dispatch("user/checkUser");
    const checkmember = to.meta.checkmember;
    const searchShow = (to.meta.search) ? to.meta.search : false;
    const headerShow = (to.meta.header) ? to.meta.header : false;
    store.commit("Public/setHeaderShow", headerShow);
    checkmemberfunc.then(function (data) {
      store.commit("Public/setSearchBox", searchShow);
      store.commit("Public/setOpenSearch", false);

      if (to.name == 'index') {
        next();
        return;
      }

      if (checkmember) {
        if (store.state.user.Login.state) {
          next();
        } else {
          next({
            name: "index"
          });
        }
      } else {
        next();
      }
    }).catch((e) => {
      next({
        name: "login"
      })
    });

    // next();
  }

  // store.dispatch("Public/PostCode");
  // store.dispatch("Public/Country");
});

router.afterEach(async (to, from, next) => {
  $('body,html').scrollTop(0, 500);
});

export default router;
