import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import app from './modules/app'
import user from './modules/user'
import getters from './getters'
import Public from './modules/Public';
import schedule from './modules/schedule';
import travel from './modules/travel';
import map from './modules/mapManager';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    route: state.route,
    lastLaunch: new Date().valueOf(),
  }),
});

export default new Vuex.Store({
  modules: {
    app,
    user,
    Public,
    schedule,
    travel,
    map
  },
  getters,
  plugins: [vuexLocal.plugin],

});
