<template>
  <div class="form-control p-0 BirthBox">
    <!-- <input type="text" > -->
    <div class="row ml-4 mt-1 B-group">
      <div class="col">
        <select name="B-year" id="B-year" v-model='data.year'>
          <option value="">{{lang.holder.year}}</option>
          <option :value="today.year()-80 + i" v-for='i in 64'>{{today.year()-80+i}}</option>
        </select>
      </div>
      <div class="col">
        <select name="B-month" id="B-month" v-model='data.month'>
          <option value="">{{lang.holder.month}}</option>
          <option :value="i" v-for='i in 12'>{{i}}</option>
        </select>
      </div>
      <div class="col">
        <select name="B-date" id="B-date" v-model='data.date'>
          <option value="">{{lang.holder.date}}</option>
          <option :value="i" v-for='i in 31'>{{i}}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import moment from "moment";
  let Birth = {}
  let nameComputed = {
    getday() {
      const to = (Birth.data.year && Birth.data.month) ? moment(Birth.data.year + "-" + (Birth
        .data
        .month + 1) + "-" + 1).subtract(1, "days").date() : 0;

      return to;
    }
  }
  export default {
    name: 'Birth',
    data() {
      const today = moment();
      Birth = {
        lang: store.state.Public.lang.Birth,
        data: {
          year: this.year,
          month: this.month,
          date: this.date
        },
        today: today
      };
      return Birth;
    },
    computed: nameComputed,
    watch: {
      "data.year": {
        handler(newValue, oldValue) {
        this.$emit('update:year', newValue);
        }
      },
      "data.month": {
        handler(newValue, oldValue) {
           this.$emit('update:month', newValue);
        }
      },
      "data.date": {
        handler(newValue, oldValue) {
           this.$emit('update:date', newValue);
        }
      }
    },
    props: [
      "year",
      "month",
      "date"
    ],
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>