import "core-js/modules/es.promise";
import Vue from 'vue';
import {
  sync
} from 'vuex-router-sync';
import './plugins/fontawesome';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/scss/style.scss';
import './assets/scss/color-gray.scss';
import './assets/css/vendors.css';
import axios from 'axios';
import {
  BootstrapVue,
  BootstrapVueIcons
} from 'bootstrap-vue'
import "./style/match.scss";
import 'vue-loading-overlay/dist/vue-loading.css';
import VueCurrencyFilter from 'vue-currency-filter';
import './service/filter/publicfilter.js';
import './directive/styleelement.js';
import VueMoment from 'vue-moment';
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import Password from 'vue-password-strength-meter';
import GanttElastic from 'gantt-elastic';
import GAuth from "vue-google-oauth2";
import moment from 'moment';
import VueQrcode from '@chenfengyuan/vue-qrcode';

import fileUpload from "vue-upload-component";
import Cropper from 'cropperjs';
import Compressor from 'compressorjs';
import ImageCompressor from "image-compressor";

import VueCarousel from 'vue-carousel';

// import '@/../node_modules/moment/locale/zh-tw.js';

vSelect.props.components = {
  default: () => ({
    OpenIndicator: {
      render: createElement => createElement('span', '🔽'),
    },
  })
};
Vue.component('v-select', vSelect);
Vue.component('Password', Password);
Vue.component('GanttElastic', GanttElastic);
Vue.component(VueQrcode.name, VueQrcode);
Vue.component('file-upload', fileUpload);
Vue.config.productionTip = false;

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_KEY,
  scope: 'profile email',
  prompt: 'select_account'
}



Vue.use(GAuth, gauthOption);
Vue.use(VueCarousel);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)
Vue.use(VueMoment, {
  moment
});
Vue.use(DatePicker);
Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 0,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true
})
// Vue.use(Cropper);
// Vue.use(Compressor);

Vue.moment().locale();
axios.defaults.headers.common['Authorization'] = "Bearer " + (localStorage.Authorization || "");
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';

moment.locale('zh-tw');

store.dispatch("Public/lang", "zh-tw");

sync(store, router);

Vue.config.productionTip = false;

// app.all('*', (req, res, next) => {
//   agent = req.headers['user-agent']
//   if agent.indexOf('Safari') > -1 and agent.indexOf('Chrome') is - 1 and agent.indexOf(
//     'OPR') is - 1
//   res.header 'Cache-Control', 'no-cache, no-store, must-revalidate'
//   res.header 'Pragma', 'no-cache'
//   res.header 'Expires', 0
//   next()
// })

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
