<template>
  <div>
    <b-modal id="modal-scoped" v-model='mdshow' :hide-footer="true" :no-close-on-esc='unlock'
      :no-close-on-backdrop='unlock'>
      <template slot="modal-header" slot-scope="{ close }">
        <h5>{{langPub.Login.forgetpassword}}</h5>
      </template>

      <template slot="default" slot-scope="{ hide,close }">
        <form action="" @submit.prevent="sendMail()" v-if='page==0'>
          <div class="form-group">
            <label for="pms-forgetpw-email">{{langPub.resign.email}}</label>
            <input type="email" v-model='email' class="form-control pl-4" name="password"
              id="pms-forgetpw-email" value="" :class='{"is-invalid":emailerror}'>
            <font-awesome-icon class='position-absolute' style='left:0.3rem;top:60%'
              :icon="['fas', 'envelope']" />
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <button class='btn btn-sm btn-primary' type='submit'>送出Email</button>
            <button class='btn btn-sm btn-danger' @click='close()'>關閉</button>
          </div>
        </form>
        <form action="" @submit.prevent="changepwfunc()" v-if='page==1'>
          <div class="form-group">
            <label for="pms-forgetpw-YouKey">{{langPub.Login.checkedYouKey}}</label>
            <input type="text" v-model='YouKey' class="form-control pl-4" name="YouKey"
              id="pms-forgetpw-YouKey" value="">
            <font-awesome-icon class='position-absolute' style='left:0.3rem;top:60%'
              :icon="['fas', 'key']" />
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <button class='btn btn-sm btn-primary' type='submit'>下一步修改密碼</button>
            <button class='btn btn-sm btn-danger' @click='close()'>關閉</button>
          </div>
        </form>
        <form action="" @submit.prevent="finish()" v-if='page==2'>
          <div class="form-group">
            <label for="pms-forgetpw-changepw">{{langPub.Login.changepw}}</label>
            <input type="password" v-model='changepw' class="form-control pl-4" name="changepw"
              id="pms-forgetpw-changepw" value="" :class='{"is-invalid":!(changepw.length>7)}'>
            <font-awesome-icon class='position-absolute' style='left:0.3rem;top:60%'
              :icon="['fas', 'key']" />
          </div>
          <div class="form-group">
            <label for="pms-forgetpw-doublechangepw">{{langPub.Login.doublechangepw}}</label>
            <input type="password" v-model='doublechangepw' class="form-control pl-4"
              name="doublechangepw" id="pms-forgetpw-doublechangepw" value=""
              :class='{"is-invalid":!(changepw==doublechangepw)}'>
            <font-awesome-icon class='position-absolute' style='left:0.3rem;top:60%'
              :icon="['fas', 'key']" />
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <button class='btn btn-sm btn-primary' type='submit'
              :disabled='!((changepw==doublechangepw)&& changepw.length>7)'>確認送出</button>
            <button class='btn btn-sm btn-danger' @click='close()'>關閉</button>
          </div>
        </form>
        <div class="mt-2 d-flex justify-content-between" v-if='page==3'>
          <p>恭喜你修改密碼成功，請用您新密碼登入</p>

          <button class='btn btn-sm btn-success' @click='close()'>回到登入</button>
        </div>

      </template>

    </b-modal>
  </div>
</template>

<script>
  import ctrl from "./js/ForgetPW";
  export default ctrl;

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>