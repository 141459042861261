import http from "./support"
export default {
  transferToTravel(data) {
    return http.post("Travel/transferToTravel", data);
  },

  getTransferProduct(uid) {
    return http.get("Travel/getTransferProduct/" + uid);
  }
}
