import axios from "axios";
import base64 from "crypto-js/enc-base64";
import CryptoJS from "crypto-js";
import moment from "moment";
import _ from "lodash"
let cancelList = {};
const MaxFile = (20000 * 1000);
const Deepdata = function (field, data, result, status) {
  if (field.indexOf("file") > -1) {
    const filefield = "file";
    _.each(field, function (item, keys) {
      filefield = (keys == 0) ? item : filefield + "[" + item + "]";
    });
    _.each(data, function (ee, ii) {
      if (ee && ee.files && ee.files[0]) {
        if (ee.files[0]) {
          const maxsize = MaxFile;
          if (ee.files[0].file.size < maxsize) {
            if (ee.files[0].name) {
              const nofiletype = ee.files[0].name.search(/(.png|.jpeg|.jpg|.gif)$/g) == -1;
              if (nofiletype) {
                const ta = ee.files[0].relativePath.split(".");
                ee.files[0].name = ee.files[0].name + "." + ta[ta.length - 1];
              }
            } else {
              ee.files[0].name = ee.files[0].relativePath;
            }

            result.append(filefield + "[" + ii + "]", ee.files[0].file, ee.files[0].name);
          } else {
            status.status = false;
            status.message.push({
              field: ii,
              content: "檔案太大請重新上傳"
            });
          }
        }
      }
    });
  } else {
    if ($.type(data) == "array" || $.type(data) == "object") {
      _.each(data, function (item, keys) {
        if (item != undefined) {
          const response = _.cloneDeep(field);
          response.push(keys);
          Deepdata(response, item, result, status);
        }
      });
    } else {
      let refield = "";
      _.each(field, function (item, keys) {
        refield = (keys == 0) ? item : refield + "[" + item + "]";
      });
      if (refield.split("_")[0] != "tmp" && refield.split(".")[0] != "tmp") {
        if ($.type(data) == "date") {
          if (data) {
            const d = moment(data);
            const outdate = d.format("YYYY-MM-DD");
            result.append(refield, outdate);
          }
        } else {
          if ($.type(data) == "number") {
            data = String(data);
          }
          if (!data) {
            data = "";
          }
          result.append(refield, data);
        }
      };
    }
  }
  return result;
}
const formdata = function (collect) {
  console.log(collect);
  let data = new FormData();
  const status = {
    status: true,
    message: []
  };
  _.each(collect, function (e, i) {
    data = Deepdata([i], e, data, status);
  });
  return {
    data: data,
    status: status
  };
};
const encrypt = (text) => {
  if (typeof (text) != "string") {
    text = JSON.stringify(text);
  }
  const ivcode = moment().format("x").substr(13);
  const iv = CryptoJS.enc.Latin1.parse(ivcode);
  const key = base64.parse(process.env.VUE_APP_KEY); //为了避免补位，直接用16位的秘钥
  // const iv = CryptoJS.enc.Latin1.parse(process.env.VUE_APP_IV); //16位初始向量
  const encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  });
  return btoa(JSON.stringify({
    iv: ivcode,
    value: encrypted.toString(),
    mac: CryptoJS.SHA512(iv).toString()
  }));
};

const tranSchedule = async (schedule, token) => {
  const bodydata = {
    cache: 'no-cache',
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    })
  }
  const fetchdata = await fetch(process.env.VUE_APP_PATH + '/TourMap/tranSchedule/' + schedule,
    bodydata).then((response) => {
    const json = response.json()
    return json
  })
  return fetchdata
}

const http = {
  "get"(path, data, config, cancelname = null) {
    let tmp = [];
    data = data || {};

    _.each(data, (item, keys) => {
      const val = typeof (item) == "string" || typeof (item) == "number" ? item : (item ?
        item.join(
          ",") : "");
      tmp.push(keys + "=" + val);
    });

    if (cancelname && cancelList[cancelname]) {
      cancelList[cancelname]();
    }

    const CancelToken = axios.CancelToken;

    return axios({
      method: 'get',
      url: process.env.VUE_APP_PATH + "/TourMap/" + path + (tmp.length > 0 ? "?" + tmp.join(
        "&") : ""),
      data: data,
      cancelToken: new CancelToken(c => {
        (cancelname) ? cancelList[cancelname] = c: "";
      })
    });
  },

  "post"(path, data, config, cancelname = null, isformdata = false,nopath = false) {
    const transForm = {
      transformRequest: [(data, headers) => {
        if (config && config.headers && config.headers['Content-Type'] ==
          "multipart/form-data") {
          return data;
        }
        let temp = {};
        if (!isformdata) {
          temp = {
            data: (data)
          };
        } else {
          config.headers = config.headers || {};
          config.headers['Content-Type'] = "multipart/form-data";
          temp = formdata(data).data;
          return temp;
        }
        temp = JSON.stringify(temp);
        return temp;
      }],

      transformResponse: [(data) => {
        if (typeof (data) == "string") {
          data = JSON.parse(data);
        }
        return data;
      }]
    }

    if (cancelname && cancelList[cancelname]) {
      cancelList[cancelname]();
    }

    const CancelToken = axios.CancelToken;
    return axios({
      method: 'post',
      url: process.env.VUE_APP_PATH + (nopath?"/":"/TourMap/") + path,
      data: data,
      cancelToken: new CancelToken(c => {
        (cancelname) ? cancelList[cancelname] = c: "";
      }),
      ...transForm,
      ...config
    });
  }
}

const rad = (d) => {
  return d * Math.PI / 180.0;
}

const GetDistance = (data) => {
  const latlng1 = data[0],
    latlng2 = data[1];
  const EARTH_RADIUS = 6378.137
  const radLat1 = rad(latlng1.lat);
  const radLat2 = rad(latlng2.lat);
  const a = radLat1 - radLat2;
  const b = rad(latlng1.lng) - rad(latlng2.lng);
  let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(
    radLat2) * Math.pow(Math.sin(b / 2), 2)));
  s = s * EARTH_RADIUS;
  s = Math.round(s * 10000) / 10000;
  return s;
}

const zoom_scale = (lat, zoom, width) => {
  const scale = 156543.03392 * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom);
  const lng = 110.574 * 1000;
  const mapwidth = width;
  const scale_m = mapwidth * 0.5 / 2 * scale * 0.8;
  return scale_m;
}

export default {
  ...http,
  encrypt: encrypt,
  zoom_scale: zoom_scale,
  GetDistance: GetDistance,
  tranSchedule: tranSchedule,
  formdata: formdata
}
