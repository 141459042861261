import sch from '@/service/schedule';
import pub from '@/service/public';
import sup from "@/service/support";
import filter from "@/service/filter/filter.sub";
import router from '@/router';
import Vue from "vue";
import _ from "lodash";

const kmh = 60;
const state = {
  changeLoading: false,
  pointLoading: false,
  showSearchBox: false,
  hideSearchBox: false,
  point: [],
  myQuote: {
    data: [],
    original: []
  },
  movelocation: {
    lastupdate: "",
    data: {},
    openshoedate: ""
  },
  autoreportGPS: {
    isCheck: [],
    mylocation: {
      lat: "",
      lng: "",
    },
    timeout: true
  },
  myschedule: [],
  dayColor: ["#ff6699", "#d56d5f", "#eea357", "#61ab72", "#00b4db", "#5372b7", "#8557a7"],
  quittype: [{
      name: "point",
      icon: "map-marker-alt"
    },
    {
      name: "food",
      icon: "utensils"
    },
    {
      name: "live",
      icon: "home"
    },
    {
      name: "traffic",
      icon: "car"
    },
    {
      name: "fun",
      icon: "shopping-cart"
    },
    // {
    //   name: "favorite",
    //   icon: "heart"
    // }
  ],

  ScheduleInfo: {
    ShareList: [{
        name: "不分享行程",
        value: "noshare"
      },
      {
        name: "分享行程",
        value: "share"
      },
    ]
  },
  SearchData: {
    data: [],
    nextpage_token: "",
    method: "",
    count: 0
  },

  modify: {
    ScheduleList: {}
  },

  edit: {
    ScheduleList: [],
    data: {},
    FirstLocation: {},
    ToLocation: [],
    ShareLevel: [{
        name: "only_read",
        value: "only_read"
      },
      {
        name: "isEdit",
        value: "isEdit"
      }
    ],
    selectKid: [{
        name: "food",
        icon: "utensils",
        value: "food"
      },
      {
        name: "live",
        icon: "home",
        value: "live"
      },
      {
        name: "traffic",
        icon: "car",
        value: "traffic"
      },
      {
        name: "point",
        icon: "map-marker-alt",
        value: "point"
      },
      {
        name: "fun",
        icon: "glass-cheers",
        value: "fun"
      }
    ],
    timetype: [{
      name: "Morning",
      value: "Morning",
    }, {
      name: "Afternoon",
      value: "Afternoon",
    }, {
      name: "Night",
      value: "Night",
    }, {
      name: "live",
      value: "live",
    }]
  },

  showPoint: {},
  searchRequest: {},
  personalList: {
    point: [],
    image: [],
    imageTemp: [],
  }
}
// getters
const getters = {
  ShoeList(state) {
    const result = [];
    Object.keys(state.movelocation.data).map((item, keys) => {
      let tmp = {
        date: item,
        sub: state.movelocation.data[item]
      };
      result.push(tmp);
    });
    return result;
  },
  GetSchedule(state) {
    let result = [];

    state.edit.ScheduleList.forEach((item, keys) => {
      item.sub.map((it, ik) => {

        const info = {
          day: keys,
          uid: it.uid,
          place_id: it.place_id,
          lat: it.lat,
          lng: it.lng,
          tagMethod: it.tagMethod,
          maintypes: it.maintypes,
          info: it,
          subshow: item.subshow,
          index: ik,
          name: it.title
        };
        result.push(info);
      });
    });

    return result;
  },
}

// actions
const actions = {
  getPersonalImage({
    state,
    commit
  }, data) {
    let result = sch.getPersonalImage(data);
    result.then((data) => {
      commit("setPersonalImage", []);
      if (data.data.state) {
        commit("setPersonalImage", data.data.result);
      }
    });
    return result;
  },
  uploadImage({
    state,
    commit
  }, data) {
    let form = new FormData;

    _.forEach(data.data, (item, key) => {
      _.forEach(item, (items, keys) => {
        if (keys == "files") {
          if (items.length > 0) {
            form.append("data[" + key + "][file]", items[0].file);
          }
        } else {
          form.append("data[" + key + "][" + keys + "]", items);
        }
      });
    });

    const result = sch.uploadImage(data.place, form);
    return result.then(data => {
      commit("setPersonalImage", []);
      if (data.data.state) {
        commit("setPersonalImage", data.data.result);
      }
    })

  },

  getOnePoint({
    commit
  }, uid) {
    commit("setShowPoint", {
      image: []
    });
    const result = sch.getOnePoint(uid);
    return result.then(data => {

      if (data.data.state) {
        commit("setShowPoint", data.data.result);
      }
    });
  },
  getQuote({
    commit,
    dispatch
  }, data) {
    const result = sch.getQuote(data);
    result.then(data => {
      if (data.data.state) {
        commit("setQuoteData", data.data.result);
      }
    });
    return result;
  },
  updateDayListPoint({
    commit,
    dispatch
  }, data) {
    console.log(data)
    const result = sch.updateDayListPoint(data);
    result.then(data => {
      console.log(data);
    });
    return result;
  },
  ShareUrl({
    commit,
    dispatch
  }, data) {
    const result = sch.ShareUrl(data);
    result.then(response => {
      if (response.data.state) {
        router.replace({
          name: "Edit",
          params: {
            Schedule: response.data.result.shareSch
          }
        });
        dispatch("Public/addAlert", {
          title: "成功加入此行程的分享",
          type: "success",
          msg: "成功加入此行程的分享"
        }, {
          root: true
        });
      } else {
        dispatch("Public/addAlert", {
          title: "此行程已關閉分享",
          type: "danger",
          msg: "此行程已關閉分享"
        }, {
          root: true
        });
        router.replace({
          name: "index"
        });
      }
    })
  },
  createSchedule({
    dispatch
  }, data) {
    const method = data.method;
    const tmpdata = data.data;
    const result = sch.createSchedule(method, tmpdata);
    result.then((data) => {});
    return result;
  },
  getSchedule({
    commit
  }, data) {
    const result = sch.getSchedule(data);
    result.then((data) => {
      if (data.data.state) {
        commit("SetSchedule", data.data.result);
      }
    })
    return result;
  },
  getScheduleDay({
    commit,
    dispatch,
    rootState
  }, uid) {
    const result = sch.getScheduleDay(uid);
    result.then((data) => {

      if (data.data.state) {
        if (!data.data.result.Schedule.Edit) {
          dispatch("Public/addAlert", {
            title: "您沒權限修改",
            type: "danger",
            msg: "此行程,您沒權限修改"
          }, {
            root: true
          });
        }
        commit("SetScheduleDay", data.data.result);
      } else {
        if (rootState.user.Login.state) {
          router.replace({
            name: "index"
          });
        } else {
          dispatch("user/openLoginBox", "", {
            root: true
          });
        }
      }
    })
    return result;
  },
  EditScheduleBtn({
    commit,
    state,
    dispatch
  }, data) {
    const result = sch.EditeScheduleInfo(data.uid, data.data);
    result.then((data) => {
      console.log(data);
      if (data.data.state) {
        dispatch("Public/addAlert", {
          title: "修改行程標題",
          type: "success",
          msg: "修改行程標題為" + data.data.result.Schedule.Title
        }, {
          root: true
        });
        // commit("SetScheduleDay", data.data.result);
      }
    })
    return result;
    // commit("SetScheduleInfo");
  },
  SearchData({
    commit,
    dispatch
  }, data) {
    console.log(data);

    commit("setPointLoading", true);
    commit("ClearSearchlList");
    const result = sch.getSearchData(data.searchtype, data.query, data.method, data.pagetoken);
    commit("SetSearchList", {
      method: data.searchtype.toLowerCase()
    });
    result.then((data) => {
      if (data.data.state) {
        commit("SetSearchList", data.data);
      } else {
        dispatch("Public/addAlert", {
          title: "搜尋範圍無資料",
          type: "danger",
          msg: "請選擇其他資訊"
        }, {
          root: true
        });
      }
      commit("setPointLoading", false);
    });
  },

  moreSearchData({
    state,
    commit
  }, data) {
    console.log(data);
    commit("setPointLoading", true);
    const result = sch.getSearchData(data.searchtype, data.query, data.method, data.pagetoken);
    result.then((data) => {
      if (data.data.state) {
        commit("SetMoreSearchList", data.data);
      }
      commit("setPointLoading", false);
    });
  },

  AddDay({
    state,
    commit,
    dispatch
  }, data) {
    const result = sch.AddDay(state.edit.data.uid, data);
    result.then((data) => {
      if (data.data.state) {
        dispatch("Public/addAlert", {
          title: "新增天數",
          type: "success",
          msg: "新增天數完成"
        }, {
          root: true
        });
        commit("SetScheduleDay", data.data.result);
      }
    })

  },
  addSchedule({
    commit,
    state,
    dispatch
  }, data) {
    const placeinfo = data.place_info;
    const lastsub = state.edit.ScheduleList[parseInt(data.selectday) - 1].sub[state.edit
      .ScheduleList[data.selectday - 1].sub.length - 1];
    const stoptime = Math.floor((parseInt(data.stoptime.hour) * 60 + parseInt(data.stoptime
      .min)));

    const tmp = {
      selectday: data.selectday,
      lat: placeinfo.lat,
      lng: placeinfo.lng,
      place_id: placeinfo.place_id,
      selectPoint: data.selectPoint,
      // starttime: starttime.hour.toString() + ":" + starttime.min.toString(),
      // endtime: endtime.hour.toString() + ":" + endtime.min.toString(),
      title: data.pointname,
      stoptime: stoptime,
      price: "----",
      info: "",
      NoChange: false,
      tagMethod: data.method,
      sourceType: placeinfo.method,
      maintypes: placeinfo.maintypes,
      address: placeinfo.address,
      rating: placeinfo.rating,
      timetype: data.timetype
    }
    const result = sch.addSchedule(state.edit.data.uid, {
      place_info: data.place_info,
      data: tmp,
      lastsub: lastsub
      // premoveTime:(lastsub)?a:0
    });

    result.then((data) => {
      console.log(data.data)
      if (data.data.state) {
        dispatch("Public/addAlert", {
          title: "新增景點",
          type: "success",
          msg: "景點名稱:" + tmp.title
        }, {
          root: true
        });
        commit("addSchedule", data.data.result);
      }
    });

    return result;
  },
  editPoint({
    commit,
    state,
    dispatch
  }, data) {
    const result = sch.editPoint(state.edit.data.uid, data);
    result.then((data) => {
      if (data.data.state) {
        dispatch("Public/addAlert", {
          title: "修正景點",
          type: "success",
          msg: "修正景點完成"
        }, {
          root: true
        });
        commit("editPoint", data.data.result);
      }
    });
    return result;
  },
  SetChangeSchedule({
    commit,
    state,
    dispatch
  }, data) {
    commit("setChangeLoading", true);
    const result = sch.changeSchedule(state.edit.data.uid, data);
    result.then((data) => {
      if (data.data.state) {
        dispatch("Public/addAlert", {
          title: "調整行程",
          type: "success",
          msg: "調整行程-完成"
        }, {
          root: true
        });
        commit("changeDay", data.data.result);
        commit("setChangeLoading", false);
      };
    });
  },
  deleteDay({
    commit,
    state,
    dispatch
  }, source) {
    const result = sch.deleteSchedule(state.edit.data.uid, source);
    result.then((data) => {
      if (data.data.state) {
        dispatch("Public/addAlert", {
          title: "刪除" + source.method,
          type: "success",
          msg: "刪除" + source.method + ":" + ((source.method == "Point") ? source.data
            .title : source.data.start)
        }, {
          root: true
        });
        commit("changeDay", data.data.result);
      }
    });
    return result;
  },
  checkEmailMember({
    commit,
    state
  }, data) {
    const result = pub.checkEmailMember(data);
    return result;
  },
  deleteSchedule({
    commit,
    state,
    dispatch
  }, data) {
    const result = sch.delMySchedule(data);
    result.then(response => {
      dispatch("Public/addAlert", {
        title: "刪除此行程",
        type: "success",
        msg: "刪除此行程-完成"
      }, {
        root: true
      });
    });
    return result;
  },
  CopySchedule({
    commit,
    state
  }, data) {
    const result = sch.CopySchedule(data);
    result.then((data) => {
      if (data.data.state) {
        dispatch("Public/addAlert", {
          title: "複製行程",
          type: "success",
          msg: "複製行程-完成"
        }, {
          root: true
        });
        commit("SetScheduleDay", data.data.result);
      }
    });
    return result;
  },
  EditStart({
    commit,
    state,
    dispatch
  }, data) {
    const result = sch.EditStart({
      Schdedule: state.edit.data.uid,
      data: data
    });
    result.then(response => {
      commit("SetDayStarttime", response.data);
    })
  },
  StartReport({
    commit,
    state,
    dispatch
  }) {
    const time = () => {
      navigator.geolocation.getCurrentPosition((position) => {
        const st = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        commit("getLocation", st);
        if (state.autoreportGPS.timeout) {
          setTimeout(time, 3000);
        }
      })
    };
    commit("StartReport");
    navigator.geolocation.getCurrentPosition((position) => {
      const st = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      commit("getLocation", st);
      setTimeout(time, 3000);
    })
  },
  getScheduleInfo({
    commit,
    dispatch
  }, data) {
    const result = sch.getScheduleInfo(data);
    return result;
  },
  ChangeParallel({
    commit,
    dispatch,
    state
  }, Infodata) {
    const result = sch.ChangeParallel(Infodata);
    result.then((data) => {
      if (data.data.state) {
        dispatch("Public/addAlert", {
          title: "變更成功",
          type: "success",
          msg: "變更成功"
        }, {
          root: true
        });
        commit("ChangeParallel", data.data.result);
      } else {
        dispatch("Public/addAlert", {
          title: "更新失敗",
          type: "danger",
          msg: "更新失敗"
        }, {
          root: true
        });
      }
    })
    return result;
  },
  setLocationLog({
    state,
    commit
  }, data) {
    navigator.geolocation.getCurrentPosition((position) => {
      const st = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      sch.setLocationLog(st, state.edit.data.uid).then((data) => {
        commit("setShoeLog", data.data);
      });
    })
  },
  getShoeLog({
    state,
    commit
  }) {
    const result = sch.getShoeLog(state.edit.data.uid, state.movelocation.lastupdate);
    result.then((data) => {
      commit("setShoe", data.data.result);
    });
  },

  transferToTravel({
    state,
    commit
  }, data) {
    const result = sch.transferToTravel(data);
    result.then((data => {
      console.log(data.data);
    }));
  },

  getPoint({
    state,
    commit
  }, data) {
    const result = sch.getPoint(data);
    result.then(data => {
      if (data.data.state) {
        commit("setPoint", data.data.result);
      }

    })
  },

  savePersonalPoint({
    state,
    dispatch,
    commit
  }, data) {
    const result = sch.savePersonalPoint(data);

    result.then(data => {
      if (data.data.state) {
        if (data.data.result.method == "update") {
          dispatch("Public/addAlert", {
            title: "修改完成",
            type: "success",
            msg: "已完成修改"
          }, {
            root: true
          });

          commit("setChangePersonalPoint", data.data.result.data);

        } else if (data.data.result.method == "delete") {
          dispatch("Public/addAlert", {
            title: "刪除完成",
            type: "success",
            msg: "已完成刪除"
          }, {
            root: true
          });

          // commit("setDeletePersonalPoint", data.data.result.data);
        } else if (data.data.result.method == "copy") {
          dispatch("Public/addAlert", {
            title: "複製完成",
            type: "success",
            msg: "已完成複製"
          }, {
            root: true
          });

          commit("setInsertPersonalPoint", data.data.result.data);
        } else {
          dispatch("Public/addAlert", {
            title: "儲存成功",
            type: "success",
            msg: "請移至「我的景點編輯」查看"
          }, {
            root: true
          });
        }
      }
    });

    return result;
  },

  getPersonalPoint({
    state,
    commit
  }) {
    const result = sch.getPersonalPoint();
    return result.then(data => {
      commit("setPersonalPoint", []);

      if (data.data.state) {
        _.forEach(data.data.result, (item, key) => {
          item.show = false;
        });

        commit("setPersonalPoint", data.data.result);
      }
    })
  },

  getPersonalPhoto({
    state,
    commit
  }) {
    const result = sch.getPersonalPhoto();
    result.then(data => {
      console.log("getPersonalPhoto/imageTemp");
      commit("setPersonalImageTemp", []);

      if (data.data.state) {
        commit("setPersonalImageTemp", data.data.result);
      }
    });

    return result;
  },



  sendToCompany({
    state,
    dispatch,
    commit
  }, data) {
    const result = sch.sendToCompany(data);
    result.then(data => {
      if (data.data.state) {
        commit("setChangePersonalPoint", data.data.result);
      } else {
        dispatch("Public/addAlert", {
          title: "失敗",
          type: "danger",
          msg: "推送失敗，請稍後再嘗試"
        }, {
          root: true
        });
      }
    });

    return result;
  },

  updateElement({
    state,
    commit
  }, data) {
    const result = sch.updateElement(data);
    result.then(data => {
      console.log(data);
    });
    return result;
  }
};
// mutations
const mutations = {
  setPersonalImageTemp(state, data) {
    state.personalList.imageTemp = data;
  },

  setPersonalImage(state, data) {
    state.personalList.image = data;
  },
  setSearchRequest(state, data) {
    state.searchRequest = data;
  },

  setChangeLoading(state, data) {
    state.changeLoading = data;
  },

  setPoint(state, data) {
    state.point = data;
  },

  redefault(state, data) {
    state.myschedule = [];
    state.SearchData = {
      data: [],
      nextpage_token: "",
      method: "",
      count: 0,
    };
    state.edit = {
      ScheduleList: [],
      data: {},
      FirstLocation: {},
      ToLocation: [],
    }
  },
  SetSchedule(state, data) {
    state.myschedule = [];
    data.map((item, keys) => {
      item.image = `https://picsum.photos/id/94/600/400`;
      item.textcolor = "";
      state.myschedule.push(item);
      return item;
    });
  },
  setQuoteData(state, data) {
    state.myQuote.data = [];
    state.myQuote.original = [];
    state.myQuote.data = data;
    state.myQuote.original = JSON.parse(JSON.stringify(data));
  },
  SetSearchList(state, data) {
    console.log(data);
    let resultdata = [];
    let nextpage = "";
    let count = 0;
    if (data && data.result) {
      resultdata = data.result.results;
      nextpage = data.result.next_page_token || "";
      count = data.result.count;
    }
    state.SearchData.count = count;
    state.SearchData.data = resultdata;
    state.SearchData.nextpage_token = nextpage;
    state.SearchData.method = data.method || "";
    state.hideSearchBox = true;
    state.showSearchBox = true;
  },

  SetMoreSearchList(state, data) {
    const resultdata = data.result.results;
    state.SearchData.nextpage_token = data.result.next_page_token;
    state.SearchData.data = state.SearchData.data.concat(resultdata);
  },

  ClearSearchlList(state) {
    state.SearchData.count = 0;
    state.SearchData.data = [];
    state.SearchData.nextpage_token = "";
    state.SearchData.method = "";
  },
  SetChangeSchedule(state, data) {
    state.edit.ScheduleList = _.cloneDeep(data);
    state.modify.ScheduleList = _.cloneDeep(data);
  },
  addSchedule(state, data) {
    state.edit.ScheduleList[parseInt(data.Day) - 1].sub = data.DayPoint;
    state.modify.ScheduleList = _.cloneDeep(state.edit.ScheduleList);
  },
  SetScheduleDay(state, data) {
    state.edit.data = data.Schedule;
    state.modify.ScheduleList = _.cloneDeep(data.Day);
    state.edit.ScheduleList = data.Day;
    state.edit.ToLocation = data.ToLocation;
    state.edit.FirstLocation = data.FirstLocation;
  },
  changeDay(state, data) {
    state.edit.data = data.Schedule;
    state.edit.ScheduleList = data.Day.map((item, keys) => {
      const iot = filter.filter(state.edit.ScheduleList, {
        uid: item.uid
      });
      if (iot) {
        item.subshow = iot[0].subshow;
      } else {
        item.subshow = false;
      }
      return item;
    });

    state.modify.ScheduleList = JSON.parse(JSON.stringify(state.edit.ScheduleList));
  },
  editPoint(state, data) {
    state.edit.ScheduleList = state.modify.ScheduleList = data.map((item, keys) => {
      const iot = filter.filter(state.edit.ScheduleList, {
        uid: item.uid
      });
      if (iot) {
        item.subshow = iot[0].subshow;
      } else {
        item.subshow = false;
      }
      return item;
    });
  },
  SetDayStarttime(state, response) {
    if (response.state) {

      const data = response.result;
      const Dayuid = data.Day[0].uid;
      state.edit.ScheduleList.forEach((item, keys) => {
        if (item.uid == Dayuid) {
          state.edit.ScheduleList[keys].starttime = data.Day[0].starttime;
          state.edit.ScheduleList[keys].sub = data.Day[0].sub;
        }
      });

      state.modify.ScheduleList.forEach((item, keys) => {
        if (item.uid == Dayuid) {
          state.modify.ScheduleList[keys].starttime = data.Day[0].starttime;
          state.modify.ScheduleList[keys].sub = data.Day[0].sub;
        }
      })
    }
  },
  getLocation(state, data) {
    state.autoreportGPS.mylocation = {
      lat: data.lat,
      lng: data.lng
    }
  },
  StopReport(state) {
    state.autoreportGPS.timeout = false;
  },
  StartReport(state) {
    state.autoreportGPS.timeout = true;
  },
  ChangeParallel(state, data) {
    state.edit.ScheduleList = state.modify.ScheduleList = state.edit.ScheduleList.map((item,
      keys) => {
      if (item.uid == data.Day) {
        item.sub = item.sub = data.DayPoint
      }
      return item;
    });
  },

  setShoe(state, data) {
    state.movelocation.lastupdate = data.gettime;
    state.movelocation.data = data.data;
  },

  setShoeLog(state, data) {
    const reuslt = data.result;
    if (state.movelocation.lastupdate) {
      state.movelocation.data[reuslt.date] = (state.movelocation.data[reuslt.date]) ? state
        .movelocation.data[reuslt.date] : []
      state.movelocation.data[reuslt.date].splice(0, 0, reuslt.data);
    }
  },

  setOpenShoeDate(state, data) {
    state.movelocation.openshoedate = data;
  },

  setPointLoading(state, data) {
    state.pointLoading = data;
  },

  setShowSearchBox(state, data) {
    state.showSearchBox = data;
  },
  setHideSearchBox(state, data) {
    state.hideSearchBox = data;
  },

  setOneScheduleDay(state, data) {
    const temp = JSON.parse(JSON.stringify(data.result));

    _.forEach(state.edit.ScheduleList, (item, key) => {
      if (item.uid == temp.uid) {
        item.googleDistance = temp.googleDistance;
        item.sub = temp.sub;
      }
    });

    _.forEach(state.modify.ScheduleList, (item, key) => {
      if (item.uid == temp.uid) {
        item.googleDistance = temp.googleDistance;
        item.sub = temp.sub;
      }
    });
  },

  setShowPoint(state, data) {
    console.log(data);
    let temp = _.cloneDeep(data);
    temp.more = false;
    temp.model = "image";

    temp.streetAndImage = [];

    if (temp.iframe != "") {
      temp.model = "street";
      let iframe = [{
        type: "street",
        html: temp.iframe
      }];

      temp.streetAndImage = iframe;
    }

    _.forEach(temp.image, (item, key) => {
      item.type = "image";
    });

    temp.streetAndImage = _.concat(temp.streetAndImage, temp.image);
    console.log(temp.streetAndImage);
    state.showPoint = temp;
  },

  setPersonalPoint(state, data) {
    state.personalList.point = data;
  },

  setChangePersonalPoint(state, data) {
    let index = _.findIndex(state.personalList.point, (item, key) => {
      return item.uid == data.belong_ele;
    });

    if (index > -1) {
      let subIndex = _.findIndex(state.personalList.point[index].sub, (item, key) => {
        return item.uid == data.uid;
      });

      if (subIndex > -1) {
        // state.personalList.point[index].sub[subIndex] = data;
        Vue.set(state.personalList.point[index].sub, subIndex, data);
      }
    }
  },

  setInsertPersonalPoint(state, data) {
    let index = _.findIndex(state.personalList.point, (item, key) => {
      return item.uid == data.belong_ele;
    });
    if (index > -1) {
      state.personalList.point[index].sub.push(data);
    }
  },



  // setPersonalPointChange(state, data){
  //   console.log(state.personalList.point, data);

  //   let index = _.findIndex(state.personalList.point, (item, key) => {
  //     return item.uid == data.belong_ele;
  //   });

  //   console.log(index);
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
