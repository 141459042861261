<template>
  <div>
    <b-modal v-model='LoginBoxdata.show' v-if='LoginBoxdata.show' footer-class='bg-white'
      :no-close-on-esc='LoginBoxdata.noClose.esc'
      :no-close-on-backdrop='LoginBoxdata.noClose.backdrop' :hide-footer='true' :centered='true'>
      <!-- @ok='' @hide='' -->
      <template slot="modal-header" slot-scope="{ close }">
        <h5>{{langLogin.LoginTitle}}</h5>
      </template>
      <template slot="default" slot-scope="{ hide }">
        <form>
          <!-- <div class="access_social">
            <a href="#0" class="social_bt google"
              @click='Login("Google")'>{{langLogin.AuthGoogle}}</a>
          </div>
          <div class="divider"><span>Or</span></div> -->
          <div class="form-group">
            <label>{{langresign.email}}</label>
            <input type="email" v-model='signdata.email' class="form-control pl-5" name="email"
              id="email">
            <font-awesome-icon class='icon-left' :icon="['fas', 'envelope']" />
          </div>
          <div class="form-group">
            <label>{{langresign.password}}</label>
            <input type="password" v-model='signdata.password' class="form-control pl-5"
              name="password" id="password" value="">
            <font-awesome-icon class='icon-left' :icon="['fas','key']" />
          </div>
          <div class="clearfix add_bottom_30">
            <div class="float-right mt-1"><a id="forgot"
                href="javascript:void(0);" @click.prevent='ForgetShow=!ForgetShow'>{{langLogin.forgetpassword}}</a></div>
          </div>
          <a href="#0" class="btn_1 rounded full-width"
            @click='Login("Account")'>{{langLogin.LoginSubmit}}</a>
          <div class="text-center add_top_10">{{langLogin.newsignpre}} <strong><a
                href="#sign-in-dialog" @click.prevent="resignopen()">{{langLogin.signtext}}
                ！</a></strong></div>
        </form>
      </template>
    </b-modal>
    <b-modal v-model='resignpage' footer-class='bg-white' :hide-footer='true' :centered='true'
      @hide='resignclose()' title='註冊'>
      <div slot="default" slot-scope="{ hide }" id="sign-in-dialog">
        <ReSignPage :caseOI='resignclose' v-if='resignpage' />
      </div>
    </b-modal>
     <ForgetPW :show.sync='ForgetShow' v-if='ForgetShow'></ForgetPW>
  </div>
</template>

<script>

  import ctrl from "./js/LoginBox";
  export default ctrl;

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>