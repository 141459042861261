import store from "@/store";
import ReSignPage from "@/components/ReSignPage.vue";

export default {
  name: 'LoginBox',
  data() {
    return {
      ForgetShow: false,
      modalShow: true,
      resignpage: false,
      signdata: {
        email: "",
        password: ""
      }
    };
  },

  methods: {
    Login(method) {
      switch (method) {
        case "Google":
          store.dispatch("user/getGoogle").then((data) => {
            store.dispatch("user/Login", {
              method: "Google",
              data: {
                googleuid: data.El
              }
            }).then((data) => {
              if (data.data.state) {
                if (this.$route.name == 'Login') {
                  this.$router.push({
                    name: "index"
                  });
                } else {
                  this.$router.go(0);
                }
              }
            });
          });
          break;
        case "Account":
          store.dispatch("user/Login", {
            method: "Account",
            data: {
              email: this.signdata.email,
              password: this.signdata.password
            }
          }).then((data) => {
            if (data.data.state) {
              if (this.$route.name == 'Login') {
                this.$router.push({
                  name: "index"
                });
              } else {
                this.$router.go(0);
              }
            }
          });
          break;
      }

    },
    test(data) {},
    resignopen() {
      this.resignpage = true;
    },
    resignclose() {
      this.resignpage = false;
    },
    resignOK(data) {
      this.resignclose();

    }

  },
  computed: {
    LoginBoxdata: {
      get() {
        return store.state.user.LoginBox;
      },
    },
    langresign: {
      get() {
        return store.state.Public.lang.resign;
      }
    },
    langLogin: {
      get() {
        return store.state.Public.lang.Login;
      }
    }
  },
  components: {
    ReSignPage
  }
};
