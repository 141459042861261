<template>
  <div class='resingpage'>

    <div class="page" :class='{"active":page==1}' v-show='page==1'>
      <form action="" @submit.prevent='next(1)'>
        <div class="form-group">
          <label>{{lang.email}}</label>
          <input type="email" v-model='data.email' class="form-control" name="email"
            :placeholder='lang.holder.email'>
          <i class="icon_mail_alt"></i>
          <font-awesome-icon class='position-absolute'
            :class='{"text-success":emailcheck,"text-danger":!emailcheck}'
            style='right:1rem;top:60%'
            :icon="['fas', ((emailcheck)?'check-circle':'minus-circle')]" />
        </div>
        <div class="form-group " :class='{"mb-0":!pwdcheck}'>
          <label>{{lang.password}}</label>
          <input type="password" style='padding-right:40px' v-model='data.password'
            :placeholder="lang.holder.password" class="form-control" name="password" value="">
          <i class="icon_lock_alt"></i>
          <font-awesome-icon class='position-absolute'
            :class='{"text-success":pwdcheck,"text-danger":!pwdcheck}' style='right:1rem;top:60%'
            :icon="['fas', ((pwdcheck)?'check-circle':'minus-circle')]" />
        </div>
        <small class='text-danger' v-if='!pwdcheck'>
          *密碼長度請輸入6位元以上，目前輸入<span style='font-size:16px'>{{data.password.length}}</span>
        </small>
        <button class="btn mos-bg-rad rounded-pill w-100"
          :disabled='!(pwdcheck && emailcheck)'>{{lang.needbox}}</button>
      </form>
    </div>

    <div class="page" :class='{"active":page==2}' v-show='page==2'>
      <form action="" @submit.prevent="finish()">
        <div class="row mb-2">
          <div class='col'>
            <ul class='list list-inline m-0 p-0'>
              <li v-if='data.googleuid'> Google:{{data.googleuid}}</li>
              <li> {{lang.email}}:{{data.account}}</li>
            </ul>
          </div>
        </div>
        <h6 class='text-center'>*必填區</h6>
        <div class="form-group">
          <label>*{{lang.name}}</label>
          <input type="text" class="form-control"
            :class='{"is-invalid":thischeck.indexOf("name")>-1}' v-model='data.name' name="name"
            :disabled='!!data.googleuid'>
          <font-awesome-icon class='icon-left' :icon="['fas', 'users']" />
        </div>
        <div class="form-group">
          <label>*{{lang.birth}}</label>
          <Birth :year.sync='data.birth.year' :month.sync='data.birth.month'
            :class='{"is-invalid":thischeck.indexOf("birth")>-1}' :date.sync='data.birth.date' />
          <font-awesome-icon class='icon-left' :icon="['fas', 'birthday-cake']" />
        </div>
        <div class="form-group">
          <label>*{{lang.phone}}</label>
          <input type="text" class="form-control" v-model='data.phone' name="phone" value=""
            :class='{"is-invalid":thischeck.indexOf("phone")>-1}'>
          <font-awesome-icon class='icon-left' :icon="['fas', 'mobile-alt']" />
        </div>
        <div class="form-group ">
          <label>*{{lang.gender_id}}</label>
          <div class="form-control pl-4" :class='{"is-invalid":thischeck.indexOf("gender_id")>-1}'>
            <select v-model='data.gender_id' name='gender_id'>
              <option value="">{{lang.holder.gender_id}}</option>
              <option value="nopublic">{{lang.nopublic}}</option>
              <option value="male">{{lang.male}}</option>
              <option value="female">{{lang.female}}</option>
            </select>
          </div>
          <font-awesome-icon class='icon-left' :icon="['fas', 'map-marker-alt']" />
        </div>

        <a href="#0" class="btn btn-secondary rounded-pill w-50"
          @click.prevent='pre(2)'>{{lang.pretext}}</a>
        <a href="#0" class="btn mos-bg-rad rounded-pill w-50"
          @click.prevent='next(2)'>{{langpub.nextpage}}</a>
      </form>
    </div>

    <div class="page" :class='{"active":page==3}' v-show='page==3'>
      <form action="" @submit.prevent="finish()">
        <h4 class='text-center'>選填區</h4>
        <div class="form-group">
          <label>{{lang.engnage}}</label>
          <input type="text" class='form-control' v-model='data.engname'>
          <font-awesome-icon class='icon-left' :icon="['fas', 'signature']" />
        </div>
        <div class="form-group">
          <label>{{lang.postcode}}</label>
          <div class="form-control pl-5">
            <select v-model='data.postelcode'>
              <option value="">{{lang.holder.postcode}}</option>
              <option :value="i.area_uid" v-for='i in postcode'>
                {{i.area_uid}}-{{i.city_name}}-{{i.area_name}}</option>
            </select>
          </div>
          <font-awesome-icon class='icon-left' :icon="['fas', 'map-marker-alt']" />
        </div>

        <div class="form-group">
          <label>{{lang.location}}</label>
          <input type="text" class="form-control" name="location" v-model='data.location' value="">
          <font-awesome-icon class='icon-left' :icon="['fas', 'map-marker-alt']" />
        </div>

        <a href="#0" class="btn btn-secondary rounded-pill w-50"
          @click.prevent='pre(3)'>{{lang.pretext}}</a>
        <a href="#0" class="btn mos-bg-rad rounded-pill w-50"
          @click.prevent='next(3)'>{{lang.finishbox}}</a>
      </form>
    </div>

    <div class="page" :class='{"active":page==4}' v-show='page==4'>
      <form action="" @submit.prevent="finish()">
        <h4 class='text-center'>確認註冊資訊</h4>
        <dl class="row">
          <dt class='col-12 text-center'>必填區</dt>
          <dt class="col-sm-12 col-md-3">{{lang.email}}</dt>
          <dd class="col-sm-12 col-md-9">{{data.email}}</dd>
          <dt class="col-sm-12 col-md-3">{{lang.password}}</dt>
          <dd class="col-sm-12 col-md-9">{{passwordshow}}</dd>
          <dt class="col-sm-12 col-md-12 text-success" v-if='!!data.googleuid'>
            {{lang.connectgoogle}}
          </dt>
          <dt class="col-sm-12 col-md-3">{{lang.name}}</dt>
          <dd class="col-sm-12 col-md-9">{{data.name}}</dd>
          <dt class="col-sm-12 col-md-3">{{lang.gender_id}}</dt>
          <dd class="col-sm-12 col-md-9">{{lang[data.gender_id]}}</dd>
          <dt class='col-12 text-center'>選填區</dt>
          <dt class="col-sm-12 col-md-3">{{lang.engname}}</dt>
          <dd class="col-sm-12 col-md-9">{{data.engname}}</dd>
          <dt class="col-sm-12 col-md-3">{{lang.postcode}}</dt>
          <dd class="col-sm-12 col-md-9">{{data.postcode}}</dd>
          <dt class="col-sm-12 col-md-3">{{lang.location}}</dt>
          <dd class="col-sm-12 col-md-9">{{data.location}}</dd>

        </dl>
        <a href="#0" class="btn btn-secondary rounded-pill w-50"
          @click.prevent='pre(4)'>{{lang.pretext}}</a>
        <a href="#0" class="btn mos-bg-rad rounded-pill w-50"
          @click.prevent='finish()'>{{lang.submittext}}</a>
      </form>
    </div>

    <div class="page" :class='{"active":page==999}' v-show='page==999'>
      <div class="row align-items-center" style='min-height:100px;'>
        <h5 class="col text-center">
          {{lang.ismember}}
        </h5>
      </div>
      <a href="#0" class="btn btn-secondary rounded-pill w-50"
        @click.prevent='pre(999)'>{{lang.pretext}}</a>
      <a href="#0" class="btn btn-danger rounded-pill w-50"
        @click.prevent='Close()'>{{langpub.close}}</a>
    </div>
  </div>
</template>

<script>
  import ctrl from "./js/ReSignPage";
  export default ctrl;

</script>

<style scoped lang="scss">
  .resingpage {
    .page {
      transform: opacity 0.5s;
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }
  }

</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
