import http from "./support"
export default {
  async resign(data) {
    const result = await http.post("Resign", data);
    return result;
  },

  async login(data) {
    const enc = http.encrypt(data);
    const result = await http.post("login/map", enc);
    return result;
  },

  async checkAccount(data) {
    const result = await http.post("Resign/checkaccount", data);
    return result;
  },

  async checkUser() {
    const result = await http.get("schedule/checkUser");
    return result;
  },
  async tranToSchedule(tranToSchedule, token) {
    const result = await http.tranSchedule(tranToSchedule, token);
    return result;
  },

  sendCheckKey: (email) => {
    const loginstate = http.post("Login/sendCheckKey", {
      email: email
    });
    return loginstate;
  },

  CheckKey: (data) => {
    const loginstate = http.post("Login/CheckKey", data);
    return loginstate;
  },

  ChangePw: (data) => {
    const loginstate = http.post("Login/ChangePw", data);
    return loginstate;
  },
}
