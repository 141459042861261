import memberApi from "@/service/user";
import axios from "axios";

let state = {
  openLogin: false,

  resigndata: {
    account: "",
    password: "",
    email: "",
    googletoken: "",
    name: "",
  },

  Login: {
    state: false,
    name: "",
    api_token: ""
  },

  LoginBox: {
    show: false,
    noClose: {
      esc: true,
      backdrop: true
    }
  }
};

const actions = {
  openLogin({
    commit
  }, status) {
    commit("setOpenLogin", status);
  },
  sendCheckKey({
    state,
    commit
  }, email) {
    const result = memberApi.sendCheckKey(email);
    return result;
  },

  CheckKey({
    state,
    commit
  }, data) {
    const result = memberApi.CheckKey(data);
    return result;
  },

  ChangePw({
    state,
    commit
  }, data) {
    const result = memberApi.ChangePw(data);
    return result;
  },

  getGoogle() {
    const result = this._vm.$gAuth.signIn();
    result.then(GoogleUser => {

    });
    return result;
  },

  async login({
    commit,
    dispatch,
    rootState
  }, data) {
    const response = await memberApi.login({
      method: data.method,
      data: data.data,
    });
    if (response.data.result.status) {
      commit("setToken", response.data.result);
    }
    // result.then((data) => {


    // commit("setUser", data.data);

    // let msg = {
    //   type: "danger",
    //   name: rootState.Public.lang.Login.loginerror
    // };

    // if (data.data.state) {
    //   msg.type = "success";
    //   msg.title = rootState.Public.lang.Login.loginsuccess;
    //   msg.msg = rootState.Public.lang.Login.loginsuccess;
    //   dispatch("hideLoginBox")
    // }

    // dispatch("Public/addAlert", msg, {
    //   root: true
    // });

    return response.data.result.status;
  },

  logout({
    commit
  }) {
    commit("schedule/redefault", "", {
      root: true
    });

    commit("logout");
  },

  resign({
    commit,
    dispatch
  }, user) {
    const method = user.method;
    let result = "";
    switch (method) {
      case "Google":
        break;
      case "check":
        result = memberApi.checkaccount(user.data);
        break;
      case "info":
        break;
      case "finish":
        result = memberApi.resign(user.data);
        break;
    }
    return result;
  },

  checkUser({
    commit,
    dispatch
  }) {
    const result = memberApi.checkUser();
    result.then((data) => {
      if (data.data.state) {
        dispatch("hideLoginBox");
      }
      commit("setUser", data.data);
    })
    return result;
  },

  openLoginBox({
    commit
  }) {
    commit("ChangeLoginBox", true);
  },

  hideLoginBox({
    commit
  }) {
    commit("ChangeLoginBox", false);
  },
  async tranToSchedule({
    commit
  }, data) {
    const response =await memberApi.tranToSchedule(data.Schedule, data.token);
    if (response.status) {
      commit("setToken", response)
    }
    return response;
  }
};

// mutations
const mutations = {
  setOpenLogin(state, status) {
    state.openLogin = status;
  },

  setToken(state, data) {
    
    localStorage.Authorization = data.api_token; // 測試
    state.Login.state = data.status;
    axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.Authorization;

    state.Login.api_token = localStorage.Authorization;
  },
  setUser(state, data) {
    if (data.status) {
      // localStorage.Authorization = data.result.api_token;
      // localStorage.Authorization = process.env.VUE_APP_TOKEN || ""; // 測試
      // axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.Authorization;
      // state.Login.state = data.state;
      // state.Login.api_token = localStorage.Authorization;
      // state.Login.uid = data.result.uid;
      state.Login.state = data.status;
      state.Login.name = data.name;
    }
  },

  logout(state, data) {
    // localStorage.Authorization = "";
    localStorage.Authorization = ""; // 測試
    axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.Authorization;
    state.Login.state = false;
    state.Login.api_token = "";
    // state.Login.uid = "";
    state.Login.name = "";
  },

  ChangeLoginBox(state, data) {
    state.LoginBox.show = data;
  }
};

const getters = {
  getApiTokenreplace(state) {
    const Aio = state.Login.api_token;
    const llp = Aio.replace(/\//ig, "&2FO").replace(/\\/ig, "&2Z7");
    return llp;

  },

  getLevel(state) {
    const field = [
      // local
      "MEM20073000010",
      "MEM20073000020", //dagee@dagee.tw

      // online
      "MEM20071000010", // dagee@dagee.tw
      "MEM20072900010", // mageecube.macma@gmail.com
      "MEM20081400040", // gohebe@gmail.com
      "MEM20081400030", // twothree416@gmail.com
      "MEM0048Z8", // admin001
      "MEM0049T0" // admin002
    ];
    return _.indexOf(field, state.Login.uid) > -1 ? true : false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
