import pbAx from "@/service/public";

const import_language = (lang, path = "lang") => {
  return import(`@/lang/${lang}`);
}

const state = {
  lang: {},
  headerShow: false,
  searchShow: false,
  openSearch: false,
  searchBar: false,
  smopen: true,
  myPoint: [null, null],
  alertbox: [],
  eleType: [],
  Country: [],
  city: [],
  postcode: [],
}

// getters

// actions
const actions = {
  async autoSave({
    commit
  }, { maintable = "", maindata = {},sublist=[] ,method=0}) {
    const tdata = {
      maintable: maintable,
      maindata: maindata,
      sublist: sublist || [],
      method: method || 0 // 1 主表多筆新增 0 主表只能新增一筆 2 複數筆父子資料新增
    };
    return await pbAx.autoSave(tdata).then((data) => {
      return data.data;
     })
  },
  getMyLocation({
    dispatch,
    commit
  }, method) {
    const result = navigator.geolocation;
    result.getCurrentPosition((data) => {
      const myPoints = data;
      const itude = [data.coords.latitude, data.coords.longitude];

      commit("setMyLocation", itude);

      if (method) {
        dispatch("map/changeMapCenter", itude, {
          root: true
        });
      }
      //   this.myPoint = itude;
      //   this.$store.dispatch("map/changeMapCenter", itude);
    }, (error) => {
      dispatch("addAlert", {
        title: "您的裝置不支援系統定位",
        type: "danger",
        msg: error.message
      })
    });

    return result;
  },

  addAlert({
    commit
  }, data) {
    // type: data.type,
    // title: data.title,
    // msg: data.msg,
    // time: 3500
    // return ;
    commit("addalertbox", data);
    setTimeout(function () {
      commit("removealertbox", 0);
    }, 5000);
  },

  lang({
    commit,
    dispatch
  }, lang) {
    import_language(lang).then((data) => {
      commit("setlang", data.default);
    });
  },

  PostCode({
    commit,
    dispatch,
    state
  }) {
    if (state.postcode.length > 0) {
      return;
    } else {
      const result = pbAx.PostCode();
      result.then(function (data) {
        const result = data.data.result;
        commit("setPostCode", result);
      });
      return result;
    }
  },

  SearchPostCode({
    commit
  }, data) {
    const result = pbAx.SearchPostCode(data.Country, data.City);
    return result;
  },

  Country({
    commit,
    state
  }) {
    if (state.postcode.length > 0) {
      return;
    } else {
      let result;
      if (state.Country.length == 0) {
        result = pbAx.Country();
        result.then(function (data) {
          const result = data.data.result;
          commit("setCountry", result);
        });
      }
      return result;
    }
  },

  getType({
    commit,
    state
  }, data) {
    const search = data;
    const result = pbAx.getdataObject(search);
    return result.then((data) => {
      commit("setType", data.data.data);
    });
  },

  getCountry({
    commit,
    state
  }, data) {
    const search = data;
    const result = pbAx.getdataObject(search);
    return result.then((data) => {
      commit("setCountry", data.data.data);
    });
  },

  getCity({
    commit,
    state
  }, data) {
    const search = data;
    const result = pbAx.getdataObject(search);
    return result.then((data) => {
      commit("setCity", data.data.data);
    });
  }

};

const mutations = {
  setType(state, data) {
    state.eleType = data;
  },

  setCountry(state, data) {
    state.Country = data;
  },

  setCountry(state, data) {
    state.city = data;
  },

  setMyLocation(state, data) {
    state.myPoint = data;
  },

  setlang(state, data) {
    state.lang = data;
  },

  setPostCode(state, data) {
    state.postcode = data;
  },

  addalertbox(state, data) {
    state.alertbox.push({
      type: data.type,
      title: data.title,
      msg: data.msg,
      time: 400
    });
  },

  removealertbox(state, index) {
    state.alertbox.splice(index, 1);
  },

  setSearchBox(state, show) {
    state.searchShow = show;
  },

  setOpenSearch(state, show) {
    state.openSearch = show;
  },

  setHeaderShow(state, show) {
    state.headerShow = show;
  },

  setSmopen(state, show) {
    state.smopen = show;
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
