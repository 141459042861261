<template>
  <div id="app">
    <router-view />
    <div class="fixed-bottom pr-3 float-right col-lg-4 col-sm-12 offset-lg-8 best-u">
      <b-toast :title="alertbox.title" toaster="b-toaster-top-center"
        v-for="(alertbox, key) in alertBox" :variant="alertbox.type" visible
        :auto-hide-delay="alertbox.time" :key="'SS__' + key">
        {{ alertbox.msg }}
      </b-toast>
      <LoginBox />


      <!-- <b-alert :show='true' fade class='' :variant="alertbox.type" v-for='alertbox in alertBox'>
        {{alertbox.name}}
      </b-alert>-->

      <!-- <b-modal :header-class='["text-center","border-bottom-2","position-relative","p-0"]'
        :body-class="['bg-light', 'shadow-in']" size='sm' v-model='openlogin' centered
        :footer-class="['bg-white', 'border-0', 'ie-footer']" @hide='resetLogin' modal-header-close="true"> -->
      <b-modal v-model="openLogin" centered size="sm" @hide="resetLogin()"
        :header-class="['py-2', 'bg-dagee']" :body-class="['p-0']"
        :footer-class="['p-0', 'bg-white']">

        <template v-slot:modal-header="{ close }">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div class="flex-fill">
              <b>會員管理</b>
            </div>
            <div @click="close()">
              <font-awesome-icon :icon="['fas', 'times']" size="lg" />
            </div>
          </div>
        </template>

        <template v-slot:default='{close}'>
          <div class="w-100">
            <div class="w-100" v-if="!loginStatus.state">
              <div class="w-100 bg-light p-3">
                <b-form-group label-cols="2" label="帳號"
                  label-class="py-0 text-center align-self-center text-black">
                  <b-form-input v-model="loginData.account" class="px-2 font-weight-normal"
                    placeholder="請輸入電子信箱">
                  </b-form-input>
                </b-form-group>

                <b-form-group class="m-0" label-cols="2" label="密碼"
                  label-class="py-0 text-center align-self-center text-black">
                  <b-form-input v-model="loginData.password" type="password"
                    class="px-2 font-weight-normal" placeholder="請輸入6 ~ 12碼字元">
                  </b-form-input>
                </b-form-group>

              </div>

              <div class="d-flex justify-content-around p-3">
                <!-- <a class="btn btn-sm bg-dagee text-white px-4" href="#sign-in-dialog"
                  v-magnificPopup={open:resignopen,close:resignclose}>
                  註冊會員
                </a> -->

                <button class="btn btn-sm bg-dagee text-white px-4">
                  註冊會員
                </button>

                <button class="btn btn-sm btn-danger px-4">
                  忘記密碼
                </button>
              </div>
            </div>
            <div class="w-100" v-if="loginStatus.state">
              <div class="d-flex align-items-center justify-content-center bg-light p-3">
                <b>歡迎，{{loginStatus.name}}</b>
              </div>
              <!-- <div class="d-flex flex-wrap align-items-center justify-content-around">
                <div class="d-flex align-items-center justify-content-center" style="width: 100px">
                  個人資料
                </div>
                <div style="width: 100px">我的行程編輯</div>
                <div style="width: 100px">我的景點編輯</div>
                <div style="width: 100px">我的簡介編輯</div>
                <div style="width: 100px">我的上傳照片</div>
              </div> -->
            </div>
          </div>
        </template>

        <template v-slot:modal-footer='{close}'>
          <div class="w-100 d-flex align-content-center justify-content-around">
            <button class="btn btn-sm bg-dagee text-white px-4" @click="Login()"
              v-if="!loginStatus.state">
              <b>登入</b>
            </button>

            <button class="btn btn-sm bg-dagee text-white px-4" @click="logout(close)"
              v-if="loginStatus.state">
              <b>登出</b>
            </button>

            <button class="btn btn-sm btn-danger px-4" @click="close">
              <b>關閉</b>
            </button>
          </div>
        </template>
      </b-modal>

      <!-- <div id="sign-in-dialog" class="zoom-anim-dialog dialog mfp-hide">
        <div class="small-dialog-header">
          <h3>註冊</h3>
        </div>
        <ReSignPage :caseOI="test" v-if="resignpage" />
      </div> -->

      <!-- <ForgetPW :show.sync='ForgetShow' v-if='ForgetShow'></ForgetPW> -->
      <!-- <b-modal v-model="openLogin" centered size="sm" @hide="resetLogin()"
        :header-class="['py-2', 'bg-dagee']" :body-class="['p-0']"
        :footer-class="['p-0', 'bg-white']">

        <template v-slot:modal-header="{ close }">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div class="flex-fill">
              <b>註冊會員</b>
            </div>
            <div @click="close()">
              <font-awesome-icon :icon="['fas', 'times']" size="lg" />
            </div>
          </div>
        </template>

        <template v-slot:default='{close}'>
          <div class="w-100 p-2">
            <div class="page" :class='{"active":page==1}' v-show='page==1'>
              <form action="" @submit.prevent='next(1)'>
                <div class="form-group">
                  <label>{{lang.email}}</label>
                  <input type="email" v-model='data.email' class="form-control" name="email"
                    :placeholder='lang.holder.email'>
                  <i class="icon_mail_alt"></i>
                  <font-awesome-icon class='position-absolute'
                    :class='{"text-success":emailcheck,"text-danger":!emailcheck}'
                    style='right:1rem;top:60%'
                    :icon="['fas', ((emailcheck)?'check-circle':'minus-circle')]" />
                </div>
                <div class="form-group " :class='{"mb-0":!pwdcheck}'>
                  <label>{{lang.password}}</label>
                  <input type="password" style='padding-right:40px' v-model='data.password'
                    :placeholder="lang.holder.password" class="form-control" name="password"
                    value="">
                  <i class="icon_lock_alt"></i>
                  <font-awesome-icon class='position-absolute'
                    :class='{"text-success":pwdcheck,"text-danger":!pwdcheck}'
                    style='right:1rem;top:60%'
                    :icon="['fas', ((pwdcheck)?'check-circle':'minus-circle')]" />
                </div>
                <small class='text-danger' v-if='!pwdcheck'>
                  *密碼長度請輸入6位元以上，目前輸入<span style='font-size:16px'>{{data.password.length}}</span>
                </small>
                <button class="btn mos-bg-rad rounded-pill w-100"
                  :disabled='!(pwdcheck && emailcheck)'>{{lang.needbox}}</button>
              </form>
            </div>

            <div class="page" :class='{"active":page==2}' v-show='page==2'>
              <form action="" @submit.prevent="finish()">
                <div class="row mb-2">
                  <div class='col'>
                    <ul class='list list-inline m-0 p-0'>
                      <li v-if='data.googleuid'> Google:{{data.googleuid}}</li>
                      <li> {{lang.email}}:{{data.account}}</li>
                    </ul>
                  </div>
                </div>
                <h6 class='text-center'>*必填區</h6>
                <div class="form-group">
                  <label>*{{lang.name}}</label>
                  <input type="text" class="form-control"
                    :class='{"is-invalid":thischeck.indexOf("name")>-1}' v-model='data.name'
                    name="name" :disabled='!!data.googleuid'>
                  <font-awesome-icon class='icon-left' :icon="['fas', 'users']" />
                </div>
                <div class="form-group">
                  <label>*{{lang.birth}}</label>
                  <Birth :year.sync='data.birth.year' :month.sync='data.birth.month'
                    :class='{"is-invalid":thischeck.indexOf("birth")>-1}'
                    :date.sync='data.birth.date' />
                  <font-awesome-icon class='icon-left' :icon="['fas', 'birthday-cake']" />
                </div>
                <div class="form-group">
                  <label>*{{lang.phone}}</label>
                  <input type="text" class="form-control" v-model='data.phone' name="phone" value=""
                    :class='{"is-invalid":thischeck.indexOf("phone")>-1}'>
                  <font-awesome-icon class='icon-left' :icon="['fas', 'mobile-alt']" />
                </div>
                <div class="form-group ">
                  <label>*{{lang.gender_id}}</label>
                  <div class="form-control pl-4"
                    :class='{"is-invalid":thischeck.indexOf("gender_id")>-1}'>
                    <select v-model='data.gender_id' name='gender_id'>
                      <option value="">{{lang.holder.gender_id}}</option>
                      <option value="nopublic">{{lang.nopublic}}</option>
                      <option value="male">{{lang.male}}</option>
                      <option value="female">{{lang.female}}</option>
                    </select>
                  </div>
                  <font-awesome-icon class='icon-left' :icon="['fas', 'map-marker-alt']" />
                </div>

                <a href="#0" class="btn btn-secondary rounded-pill w-50"
                  @click.prevent='pre(2)'>{{lang.pretext}}</a>
                <a href="#0" class="btn mos-bg-rad rounded-pill w-50"
                  @click.prevent='next(2)'>{{langpub.nextpage}}</a>
              </form>
            </div>

            <div class="page" :class='{"active":page==3}' v-show='page==3'>
              <form action="" @submit.prevent="finish()">
                <h4 class='text-center'>選填區</h4>
                <div class="form-group">
                  <label>{{lang.engnage}}</label>
                  <input type="text" class='form-control' v-model='data.engname'>
                  <font-awesome-icon class='icon-left' :icon="['fas', 'signature']" />
                </div>
                <div class="form-group">
                  <label>{{lang.postcode}}</label>
                  <div class="form-control pl-5">
                    <select v-model='data.postelcode'>
                      <option value="">{{lang.holder.postcode}}</option>
                      <option :value="i.area_uid" v-for='i in postcode'>
                        {{i.area_uid}}-{{i.city_name}}-{{i.area_name}}</option>
                    </select>
                  </div>
                  <font-awesome-icon class='icon-left' :icon="['fas', 'map-marker-alt']" />
                </div>

                <div class="form-group">
                  <label>{{lang.location}}</label>
                  <input type="text" class="form-control" name="location" v-model='data.location'
                    value="">
                  <font-awesome-icon class='icon-left' :icon="['fas', 'map-marker-alt']" />
                </div>

                <a href="#0" class="btn btn-secondary rounded-pill w-50"
                  @click.prevent='pre(3)'>{{lang.pretext}}</a>
                <a href="#0" class="btn mos-bg-rad rounded-pill w-50"
                  @click.prevent='next(3)'>{{lang.finishbox}}</a>
              </form>
            </div>

            <div class="page" :class='{"active":page==4}' v-show='page==4'>
              <form action="" @submit.prevent="finish()">
                <h4 class='text-center'>確認註冊資訊</h4>
                <dl class="row">
                  <dt class='col-12 text-center'>必填區</dt>
                  <dt class="col-sm-12 col-md-3">{{lang.email}}</dt>
                  <dd class="col-sm-12 col-md-9">{{data.email}}</dd>
                  <dt class="col-sm-12 col-md-3">{{lang.password}}</dt>
                  <dd class="col-sm-12 col-md-9">{{passwordshow}}</dd>
                  <dt class="col-sm-12 col-md-12 text-success" v-if='!!data.googleuid'>
                    {{lang.connectgoogle}}
                  </dt>
                  <dt class="col-sm-12 col-md-3">{{lang.name}}</dt>
                  <dd class="col-sm-12 col-md-9">{{data.name}}</dd>
                  <dt class="col-sm-12 col-md-3">{{lang.gender_id}}</dt>
                  <dd class="col-sm-12 col-md-9">{{lang[data.gender_id]}}</dd>
                  <dt class='col-12 text-center'>選填區</dt>
                  <dt class="col-sm-12 col-md-3">{{lang.engname}}</dt>
                  <dd class="col-sm-12 col-md-9">{{data.engname}}</dd>
                  <dt class="col-sm-12 col-md-3">{{lang.postcode}}</dt>
                  <dd class="col-sm-12 col-md-9">{{data.postcode}}</dd>
                  <dt class="col-sm-12 col-md-3">{{lang.location}}</dt>
                  <dd class="col-sm-12 col-md-9">{{data.location}}</dd>

                </dl>
                <a href="#0" class="btn btn-secondary rounded-pill w-50"
                  @click.prevent='pre(4)'>{{lang.pretext}}</a>
                <a href="#0" class="btn mos-bg-rad rounded-pill w-50"
                  @click.prevent='finish()'>{{lang.submittext}}</a>
              </form>
            </div>

            <div class="page" :class='{"active":page==999}' v-show='page==999'>
              <div class="row align-items-center" style='min-height:100px;'>
                <h5 class="col text-center">
                  {{lang.ismember}}
                </h5>
              </div>
              <a href="#0" class="btn btn-secondary rounded-pill w-50"
                @click.prevent='pre(999)'>{{lang.pretext}}</a>
              <a href="#0" class="btn btn-danger rounded-pill w-50"
                @click.prevent='Close()'>{{langpub.close}}</a>
            </div>
          </div>
        </template>
        <template v-slot:modal-footer='{close}'>
          <div class="w-100 d-flex align-content-center justify-content-around">
            <button class="btn btn-sm text-success text-white px-4" @click="register(close)">
              <font-awesome-icon :icon="['fas', 'check']" size="lg" />
            </button>

            <button class="btn btn-sm text-danger px-4" @click="close">
              <font-awesome-icon :icon="['fas', 'times']" size="lg" />
            </button>
          </div>
        </template>
      </b-modal> -->
    </div>
  </div>
</template>
<script>
  import Vue from "vue";
  import Loading from "vue-loading-overlay";
  import store from "@/store";
  import LoginBox from "@/components/LoginBox";
  import ReSignPage from "@/components/ReSignPage.vue";
  import ForgetPW from "@/components/ForgetPW.vue";
  import Birth from '@/components/Input/Birth';
  Vue.use(Loading);

  export default {
    data() {
      return {
        resignpage: false,
        refreshing: false,
        registration: null,
        updateExists: false,
        pub: store.state.Public,
        loginData: {
          account: "",
          password: ""
        },

        page: this.part || 1,

        pwdcheck: false,
        emailcheck: false,
        postcode: store.state.Public.postcode,
        thischeck: [],
        data: {
          account: "",
          password: "",
          name: "",
          googleuid: "",
          email: "",
          phone: "",
          engname: "",
          postelcode: "",
          location: "",
          gender_id: "",
          birth: {
            year: "",
            month: "",
            date: ""
          },
          photo: ""
        }
      };
    },
    computed: {
      loginStatus: {
        get() {
          return this.$store.state.user.Login;
        }
      },

      openLogin: {
        get() {
          return this.$store.state.user.openLogin;
        },

        set(status) {
          this.$store.dispatch("user/openLogin", status);
        }
      },

      alertBox: {
        get() {
          return store.state.Public.alertbox;
        }
      },

      lang: {
        get() {
          return store.state.Public.lang.resign;
        }
      },

      langpub: {
        get() {
          return store.state.Public.lang.public;
        }
      },

      passwordshow() {
        return this.data.password.slice(0, 4) + "*******";
      },

    },

    components: {
      LoginBox,
      ReSignPage,
      ForgetPW,
      Birth
    },

    created() {
      document.addEventListener("swUpdated", this.showRefreshUI, {
        once: true
      });

      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    },

    methods: {
      Login() {
        store.dispatch("user/login", {
          method: "Account",
          data: {
            email: this.loginData.account,
            password: this.loginData.password
          }
        }).then((data) => {
          if (data.data.state) {
            store.dispatch("schedule/getSchedule", "MySchedule");

            if (this.$route.name == 'Login') {
              this.$router.push({
                name: "index"
              });
            } else {
              this.$router.go(0);
            }
          }
        });
      },

      logout(close) {
        this.$router.push({
          name: "logout"
        });

        close();
      },

      resetLogin() {
        this.loginData = {
          account: "",
          password: ""
        }
      },

      next(page) {
        if (page == 1 && this.data.email != "" && this.data.password) {
          store.dispatch("user/resign", {
            method: "check",
            data: {
              email: this.data.email
            }
          }).then((data) => {
            if (data.data.result.msg) {
              this.page = 999;
            } else {
              this.data.account = this.data.email;
              this.page++;
            }
          });
        } else if (page == 2) {
          const checkfield = ["birth", "name", "phone", "gender_id"];
          this.thischeck = checkfield.map((item, keys) => {
            if (this.data[item]) {
              if (item == "birth") {
                if (this.data[item].year && this.data[item].month && this.data[item]
                  .date) {
                  return;
                } else {
                  return item;
                }
              } else {
                return;
              }
            } else {
              return item;
            }
          }).filter((item) => {
            return item;
          });

          if (this.thischeck.length == 0) {
            this.page++;
            this.thischeck = [];
          } else {
            store.dispatch("Public/addAlert", {
              type: "danger",
              title: "請務必填寫",
              msg: "此區欄位都是必填"
            });
          }
        } else {
          this.page++;
        }
      },

      pre(page) {
        if (page == 2) {
          this.data.account = this.data.email;
          this.data.password = "";
          this.page--;
        } else if (page == 999) {
          this.page = 1;
        } else {
          this.page--;
        }
      },

      finish() {
        const result = store.dispatch("user/resign", {
          method: "finish",
          data: this.data
        });
        result.then((data) => {
          if (data.data.result.inMember) {
            store.dispatch("Public/addAlert", {
              type: "success",
              title: "註冊完成",
              msg: "註冊完成"
            });
            if (this.caseOI) {
              this.caseOI(data.data.result);
            }
            this.Close();
          };
        })
      },
    }
  };

</script>

<style lang="scss">
  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

</style>
